export const firebaseConfig = {
  apiKey: 'AIzaSyAC5lVeg-oJuZWZZ3czc35iJ3zgfAj0KZg',
  authDomain: 'nft-chronicle.firebaseapp.com',
  projectId: 'nft-chronicle',
  storageBucket: 'nft-chronicle.appspot.com',
  messagingSenderId: '844694442268',
  appId: '1:844694442268:web:0fe756d121d81609572ad9',
  measurementId: 'G-HMDCBHMJGX',
};

export const devFirebaseConfig = {
  apiKey: "AIzaSyBK5juol5DdnDOwuqOy9SDqWb_AUbVE6xo",
  authDomain: "chronicle-auth-aacc8.firebaseapp.com",
  projectId: "chronicle-auth-aacc8",
  storageBucket: "chronicle-auth-aacc8.appspot.com",
  messagingSenderId: "1088460863142",
  appId: "1:1088460863142:web:fc5d83e867e8299e2ae667",
  measurementId: "G-FGGXW0P86Q"
};
